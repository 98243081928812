import {SEND_MESSAGE_FAIL, SEND_MESSAGE_REQUEST, SEND_MESSAGE_SUCCESS} from "../constants/contactConstants";
import {handleError} from "../utils/errorMessageUtils";
import axios from "axios";


import config from "../config";
const apiBaseUrl = `${config.PROTOCOL}://${config.HOST}${config.PORT ? `:${config.PORT}` : ""}`;
export const createMessageUrl = `${apiBaseUrl}/contact/contact/`;

export const createMessage = (name, email, subject, message) => async (dispatch, getState) => {
  try {
    dispatch({ type: SEND_MESSAGE_REQUEST });
    const { userLogin: { userInfo } } = getState();

    // Ensure userInfo exists and has an access token
    const axiosConfig = userInfo && userInfo.access
      ? { headers: { Authorization: `Bearer ${userInfo.access}` } }
      : {};

    const { data } = await axios.post(
      createMessageUrl,
      { name, email, subject, message },
      axiosConfig
    );

    dispatch({ type: SEND_MESSAGE_SUCCESS, payload: data });
  } catch (error) {
    const errorMessage = handleError(error, dispatch);
    dispatch({ type: SEND_MESSAGE_FAIL, payload: errorMessage });
  }
};
