// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Logo Text Container */
.logo-text {
    text-align: center;
    font-family: Arial, sans-serif;
    color: #333;
}

/* Haupttext mit hervorgehobenen Buchstaben */
.primary-text {
    font-size: 10px;
    font-weight: bold;
    letter-spacing: 1px;
    text-transform: uppercase;
    margin-bottom: 1px;
    display: block;
    color: white;
}

.highlight-letter {
    font-size: 16px;
    font-weight: bold;
    color: white;
}


.parallelogram {
    display: inline-block;
    background-color: #333;
    transform: skew(-20deg);
    padding: 4px 16px;
    margin-top: 4px;
}


.secondary-text {
    font-size: 10px;
    color: white;
    transform: skew(20deg);
    display: block;
    letter-spacing: 0.5px;
}
.primary-text-login{
    font-size: 15px;
    font-weight: bold;
    letter-spacing: 1px;
    text-transform: uppercase;
    margin-bottom: 1px;
    display: block;
    color: white;
}

.highlight-letter-login {
    font-size: 16px;
    font-weight: bold;
    color: black;
}`, "",{"version":3,"sources":["webpack://./src/style/logoStyle.css"],"names":[],"mappings":"AAAA,wBAAwB;AACxB;IACI,kBAAkB;IAClB,8BAA8B;IAC9B,WAAW;AACf;;AAEA,6CAA6C;AAC7C;IACI,eAAe;IACf,iBAAiB;IACjB,mBAAmB;IACnB,yBAAyB;IACzB,kBAAkB;IAClB,cAAc;IACd,YAAY;AAChB;;AAEA;IACI,eAAe;IACf,iBAAiB;IACjB,YAAY;AAChB;;;AAGA;IACI,qBAAqB;IACrB,sBAAsB;IACtB,uBAAuB;IACvB,iBAAiB;IACjB,eAAe;AACnB;;;AAGA;IACI,eAAe;IACf,YAAY;IACZ,sBAAsB;IACtB,cAAc;IACd,qBAAqB;AACzB;AACA;IACI,eAAe;IACf,iBAAiB;IACjB,mBAAmB;IACnB,yBAAyB;IACzB,kBAAkB;IAClB,cAAc;IACd,YAAY;AAChB;;AAEA;IACI,eAAe;IACf,iBAAiB;IACjB,YAAY;AAChB","sourcesContent":["/* Logo Text Container */\n.logo-text {\n    text-align: center;\n    font-family: Arial, sans-serif;\n    color: #333;\n}\n\n/* Haupttext mit hervorgehobenen Buchstaben */\n.primary-text {\n    font-size: 10px;\n    font-weight: bold;\n    letter-spacing: 1px;\n    text-transform: uppercase;\n    margin-bottom: 1px;\n    display: block;\n    color: white;\n}\n\n.highlight-letter {\n    font-size: 16px;\n    font-weight: bold;\n    color: white;\n}\n\n\n.parallelogram {\n    display: inline-block;\n    background-color: #333;\n    transform: skew(-20deg);\n    padding: 4px 16px;\n    margin-top: 4px;\n}\n\n\n.secondary-text {\n    font-size: 10px;\n    color: white;\n    transform: skew(20deg);\n    display: block;\n    letter-spacing: 0.5px;\n}\n.primary-text-login{\n    font-size: 15px;\n    font-weight: bold;\n    letter-spacing: 1px;\n    text-transform: uppercase;\n    margin-bottom: 1px;\n    display: block;\n    color: white;\n}\n\n.highlight-letter-login {\n    font-size: 16px;\n    font-weight: bold;\n    color: black;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
