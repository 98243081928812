// src/ContactComponent.js
import React, { useState } from 'react';

function ContactComponent() {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        alert('Nachricht gesendet!');
    };

    return (
        <section className="py-16 bg-gray-100 text-center">
            <h2 className="text-3xl font-bold mb-8">Kontaktieren Sie uns</h2>
            <form onSubmit={handleSubmit} className="max-w-lg mx-auto bg-white p-6 rounded-lg shadow-lg">
                <input
                    type="text"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    placeholder="Ihr Name"
                    className="w-full p-3 mb-4 border border-gray-300 rounded-md"
                />
                <input
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    placeholder="Ihre E-Mail"
                    className="w-full p-3 mb-4 border border-gray-300 rounded-md"
                />
                <textarea
                    name="message"
                    value={formData.message}
                    onChange={handleChange}
                    placeholder="Ihre Nachricht"
                    className="w-full p-3 mb-4 border border-gray-300 rounded-md"
                />
                <button type="submit" className="bg-blue-500 hover:bg-blue-600 text-white py-3 px-8 rounded-md">
                    Senden
                </button>
            </form>
        </section>
    );
}

export default ContactComponent;
