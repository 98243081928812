import React from 'react';
import Toast from './Toast';

function ToastContainer({ toasts }) {
  return (
    <div className="absolute z-50">
      {toasts.map((toast) => (
        <Toast
          key={toast.id}
          message={toast.message}
          type={toast.type}
          position={toast.position}
          onClose={() => {}}
        />
      ))}
    </div>
  );
}

export default ToastContainer;
