import {
    SEND_MESSAGE_FAIL,
    SEND_MESSAGE_REQUEST,
    SEND_MESSAGE_RESET,
    SEND_MESSAGE_SUCCESS
} from "../constants/contactConstants";

export const messageCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case SEND_MESSAGE_REQUEST:
      return {
        loading: true,
      };
    case SEND_MESSAGE_SUCCESS:
      return {
        loading: false,
        success: true,
      };
    case SEND_MESSAGE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case SEND_MESSAGE_RESET:
      return {};
    default:
      return state;
  }
};