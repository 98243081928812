import { configureStore } from "@reduxjs/toolkit";
import {updateAvatarReducer, userInfoReducer, userListReducer, userLoginReducer} from "./reducers/userReducer";
import {messageCreateReducer} from "./reducers/contactReducer";

// Initiales Zustand des Stores
const userInfoFromStorage = localStorage.getItem("userInfo")
  ? JSON.parse(localStorage.getItem("userInfo"))
  : null;

const initialState = {
  userLogin: { userInfo: userInfoFromStorage },
};

const store = configureStore({
  reducer: {
    userLogin: userLoginReducer,
    userInfo: userInfoReducer,
    updateAvatar: updateAvatarReducer,
    userList: userListReducer,
    sendMessage:messageCreateReducer
  },
  preloadedState: initialState,
});

export default store;
