import React from 'react';
import { useDispatch } from 'react-redux';
import { logout } from '../actions/userActions';
import {
  FaUserPlus, FaRegClock, FaCookieBite, FaEye
} from "react-icons/fa";
import SocialMediaCard from "../components/dashboard/SocialMediaCard";
import CustomerRequests from "../components/dashboard/CustomerRequests";
import UserInfo from "../components/dashboard/UserInfo";
import UserList from "../components/dashboard/UserList";
import NavComponent from "../components/NavComponent";
import StatisticsCard from "../components/dashboard/StatisticsCard";
import CityStatisticsCard from "../components/dashboard/CityStatisticsCard";

const AdminDashboardScreen = () => {
  const dispatch = useDispatch();

  const handleLogout = () => {
    dispatch(logout());
  };

  const currentUser = {
    name: '',
    email: '',
    phone: '',
    address: '',
    avatar: "",
    position: "",
  };

  const visitorStatistics = [
    { title: "Total Visits", value: "5,230", description: "Total site visits recorded", icon: <FaEye /> },
    { title: "New Visitors", value: "3,450", description: "Unique new visitors", icon: <FaUserPlus /> },
    { title: "Avg. Session Duration", value: "5 min 23 sec", description: "Average time spent on site", icon: <FaRegClock /> },
    { title: "Cookie Consents", value: "4,200", description: "Visitors who accepted cookies", icon: <FaCookieBite /> }
  ];

  const topCities = [
    { city: "Berlin", deviceCount: 120 },
    { city: "Munich", deviceCount: 95 },
    { city: "Hamburg", deviceCount: 85 }
  ];

  return (
    <div className="min-h-screen bg-gradient-to-r from-gray-100 to-blue-50 transition-all">
      <NavComponent />
      <div className="p-8 space-y-12 max-w-screen-xl mx-auto">

        {/* Admin Profile Section */}
        <div className="bg-white rounded-lg shadow-lg p-8 transition-transform transform hover:scale-105">
          <UserInfo user={currentUser} isOwnProfile={true} />
          <button
            onClick={handleLogout}
            className="mt-4 bg-red-500 text-white py-2 px-4 rounded hover:bg-red-600 transition-colors duration-200"
          >
            Logout
          </button>
        </div>

        {/* Visitor Statistics Section */}
        <h2 className="text-2xl font-semibold text-gray-700 mt-8">Visitor Statistics</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          {visitorStatistics.map((stat, index) => (
            <StatisticsCard
              key={index}
              title={stat.title}
              value={stat.value}
              description={stat.description}
              icon={stat.icon}
            />
          ))}
        </div>

        {/* Top Cities Section */}
        <h2 className="text-2xl font-semibold text-gray-700 mt-8">Top Cities</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {topCities.map((cityData, index) => (
            <CityStatisticsCard
              key={index}
              city={cityData.city}
              deviceCount={cityData.deviceCount}
            />
          ))}
        </div>

        {/* Social Media Overview */}
        <h2 className="text-2xl font-semibold text-gray-700 mt-8">Social Media Overview</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          <SocialMediaCard platform="Facebook" stats={{ friends: '89K', feeds: '459' }} />
          <SocialMediaCard platform="TikTok" stats={{ followers: '500K', videos: '1.200' }} />
          <SocialMediaCard platform="LinkedIn" stats={{ contacts: '500', feeds: '1.292' }} />
        </div>

        {/* Team Section */}
        <h2 className="text-2xl font-semibold text-gray-700 mt-8">Team</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          <UserList />
        </div>

        {/* Customer Requests */}
        <h2 className="text-2xl font-semibold text-gray-700 mt-8">Customer Requests</h2>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
          <CustomerRequests />
        </div>
      </div>
    </div>
  );
};

export default AdminDashboardScreen;
