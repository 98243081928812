// src/HeaderComponent.js
import React from 'react';
import backgroundVideo from '../assets/background-video.mp4'; // Importiere das Video

function HeaderComponent({ backgroundType, backgroundContent, height = '100vh', children }) {
    return (
        <header className="relative w-full" style={{ height, minHeight: '300px' }}>
            {backgroundType === 'video' ? (
                <video className="absolute top-0 left-0 w-full h-full object-cover z-0" autoPlay muted loop>
                    <source src={backgroundVideo} type="video/mp4" />
                    Dein Browser unterstützt das Video-Tag nicht.
                </video>
            ) : (
                <div
                    className="absolute top-0 left-0 w-full h-full bg-cover bg-center z-0"
                    style={{ backgroundImage: `url(${backgroundContent})` }}
                />
            )}

            {/* Flexbox für zentrierten Inhalt */}
            <div className="absolute top-0 left-0 w-full h-full flex justify-center items-center z-10 text-white">
                {/* Hier kannst du die Textgröße anpassen */}
                <div className="text-center">{children}</div>
            </div>
        </header>
    );
}

export default HeaderComponent;
