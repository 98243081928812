// src/HomepageScreen.js
import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import NavComponent from "../components/NavComponent";
import HeaderComponent from "../components/HeaderComponent";
import ServiceListComponent from "../components/ServiceListComponent";
import PricingComponent from "../components/PricingComponent";
import TestimonialComponent from "../components/TestimonialComponent";
import ContactComponent from "../components/ContactComponent";
import ParallaxSection from "../components/ParallaxSection";
import UniqueComponent from "../components/UniqueComponent";

function HomepageScreen() {
    useEffect(() => {
        AOS.init({
            duration: 1000,
            once: false,
            offset: 100,
        });

        const handleScroll = () => {
            AOS.refresh();
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <div >
            <NavComponent />
            {/* Header mit Video-Hintergrund und benutzerdefiniertem Inhalt */}
            <HeaderComponent backgroundType="video" >
                <h1 className="text-5xl" data-aos="fade-down">Willkommen auf unserer Seite!</h1>
            </HeaderComponent>

            <UniqueComponent />
            {/* Alternativ Header mit Bild-Hintergrund */}
            <HeaderComponent
                backgroundType="image"
                backgroundContent="https://www.example.com/your-background-image.jpg"
                height="50vh"
            >
                <h1 className="text-5xl" data-aos="fade-up">Willkommen auf unserer Seite!</h1>
            </HeaderComponent>

            {/* ParallaxSection mit Animationen */}
            <ParallaxSection backgroundColor="linear-gradient(135deg, #1f2937, #4b5563)">
                <div className="flex flex-col justify-center items-center text-center h-full text-white p-8">
                    <h2 className="text-4xl font-bold mb-6" data-aos="fade-up">Ihr Partner für erstklassige Reinigungsdienste</h2>
                    <p className="text-lg max-w-3xl mb-6" data-aos="fade-up" data-aos-delay="100">
                        Wir bieten professionelle Reinigungsdienste für Ihr Zuhause und Büro. Unser engagiertes Team sorgt dafür, dass Ihre Räume stets sauber und gepflegt sind.
                    </p>
                    <p className="text-lg max-w-3xl mb-6" data-aos="fade-up" data-aos-delay="200">
                        Ob einmalige Reinigung oder regelmäßige Wartung – wir bieten flexible Pakete, die perfekt zu Ihren Bedürfnissen passen.
                    </p>
                    <button className="mt-6 bg-white text-gray-900 py-3 px-8 rounded-full text-xl hover:bg-gray-200 transition duration-300" data-aos="fade-up" data-aos-delay="300">
                        Jetzt unverbindlich anfragen
                    </button>
                </div>
            </ParallaxSection>

            {/* Weitere Abschnitte */}
            <ServiceListComponent />

            {/* Parallax Section 2: Preismodelle */}
            <ParallaxSection backgroundImage="https://www.example.com/your-background-image.jpg">
                <div className="flex flex-col justify-center items-center text-center h-full text-white">
                    <h2 className="text-4xl font-bold mb-4" data-aos="fade-down">Unsere Preismodelle</h2>
                    <p className="text-lg max-w-2xl mb-4" data-aos="fade-down" data-aos-delay="100">
                        Wählen Sie das Preismodell, das am besten zu Ihren Bedürfnissen passt. Wir bieten Transparenz und Flexibilität.
                    </p>
                    <button className="mt-6 bg-transparent border-2 border-white text-white py-2 px-6 rounded-full text-xl hover:bg-white hover:text-gray-800 transition duration-300" data-aos="fade-down" data-aos-delay="200">
                        Jetzt ansehen
                    </button>
                </div>
            </ParallaxSection>

            {/* Pricing Component */}
            <PricingComponent />

            {/* Testimonial Section */}
            <TestimonialComponent />

            {/* Parallax Section 3: Kontakt */}
            <ParallaxSection backgroundColor="linear-gradient(135deg, #fbc2eb, #a6c1ee)">
                <div className="flex flex-col justify-center items-center text-center h-full">
                    <h2 className="text-4xl font-bold text-white mb-4" data-aos="fade-up">Kontaktieren Sie uns</h2>
                    <p className="text-lg text-white max-w-2xl" data-aos="fade-up" data-aos-delay="100">
                        Haben Sie Fragen oder möchten Sie ein Angebot anfordern? Wir sind gerne für Sie da.
                    </p>
                    <button className="mt-6 bg-white text-primary-700 py-2 px-6 rounded-full text-xl hover:bg-opacity-80 transition duration-300" data-aos="fade-up" data-aos-delay="200">
                        Jetzt kontaktieren
                    </button>
                </div>
            </ParallaxSection>

            {/* Contact Section */}
            <ContactComponent />
        </div>
    );
}

export default HomepageScreen;
