// src/components/dashboard/CityStatisticsCard.js
import React from 'react';

const CityStatisticsCard = ({ city, deviceCount }) => {
  return (
    <div className="bg-gradient-to-br from-green-100 via-blue-100 to-purple-100 rounded-lg shadow-md p-4 text-center">
      <h3 className="text-xl font-semibold text-gray-800">{city}</h3>
      <p className="text-3xl font-bold text-gray-900 my-2">{deviceCount}</p>
      <p className="text-sm text-gray-500">Devices in use</p>
    </div>
  );
};

export default CityStatisticsCard;
