import React from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import { Provider, useSelector } from "react-redux";
import store from "./store";
import HomepageScreen from "./screens/HomepageScreen";
import LoginScreen from "./screens/LoginScreen";
import ContactScreen from "./screens/ContactScreen";
import AdminDashboardScreen from "./screens/AdminDashboard";

const PrivateRoute = ({ element }) => {
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin || {};

  return userInfo ? element : <Navigate to="/login" replace />;
};

const App = () => {
  return (
    <Provider store={store}>
      <Router>
        <Routes>
          <Route path="/" element={<HomepageScreen />} />
          <Route path="/contact" element={<ContactScreen />} />
          <Route path="/login" element={<LoginScreen />} />
          <Route path="/admin" element={<PrivateRoute element={<AdminDashboardScreen />} />} />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </Router>
    </Provider>
  );
};

export default App;
