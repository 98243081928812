import {
  EXPIRED_TOKEN_MESSAGE,
  USER_EXPIRED_TOKEN,
} from "../constants/userConstants";
export const ERROR_MESSAGES = {
  UNEXPECTED: "An unexpected error occurred",
  NO_RESPONSE: "No response received from server",
  TABLE_CREATE_FAIL: "Failed to create table",
  NETWORK_ERROR: "Network error occurred",
  UNAUTHORIZED: "Unauthorized access",
  NOT_FOUND: "Resource not found",
  // Add more if needed
};

export const handleError = (error, dispatch) => {
  console.error("API call failed:", error);
  let errorMessage = ERROR_MESSAGES.UNEXPECTED;

  if (error.response) {
    console.error("Error response:", error.response);
    if (error.response.status === 400) {
      errorMessage =
        error.response.data.detail ||
        error.response.data.message ||
        "Bad Request: Invalid input";
    } else if (error.response.status === 401) {
      dispatch({ type: USER_EXPIRED_TOKEN, payload: EXPIRED_TOKEN_MESSAGE });
      errorMessage = ERROR_MESSAGES.UNAUTHORIZED;
    } else if (error.response.status === 404) {
      errorMessage = ERROR_MESSAGES.NOT_FOUND;
    } else {
      errorMessage =
        error.response.data.detail ||
        error.response.data.message ||
        error.response.data ||
        ERROR_MESSAGES.UNEXPECTED;
    }
  } else if (error.request) {
    console.error("Error request:", error.request);
    errorMessage = ERROR_MESSAGES.NO_RESPONSE;
  } else {
    console.error("Error message:", error.message);
    errorMessage = error.message || ERROR_MESSAGES.NETWORK_ERROR;
  }

  console.log("Returned error message:", errorMessage);
  return errorMessage;
};
