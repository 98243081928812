export const USER_LOGIN_REQUEST = "USER_LOGIN_REQUEST";
export const USER_LOGIN_SUCCESS = "USER_LOGIN_SUCCESS";
export const USER_LOGIN_FAIL = "USER_LOGIN_FAIL";
export const USER_LOGIN_RESET = "USER_LOGIN_RESET";
export const USER_LOGOUT = "USER_LOGOUT";
export const USER_LOGOUT_REQUEST = "USER_LOGOUT_REQUEST";
export const USER_LIST_RESET = "USER_LIST_RESET";
export const USER_LOGOUT_SUCCESS = "USER_LOGOUT_SUCCESS";
export const USER_EXPIRED_TOKEN = "USER_EXPIRED_TOKEN";
export const USER_EXPIRED_TOKEN_RESET = "USER_EXPIRED_TOKEN_REST";
export const EXPIRED_TOKEN_MESSAGE = "try to log in again";


export const USER_INFO_REQUEST = "USER_INFO_REQUEST";
export const USER_INFO_SUCCESS = "USER_INFO_SUCCESS";
export const USER_INFO_FAIL = "USER_INFO_FAIL";


export const USER_UPDATE_AVATAR_REQUEST = "USER_UPDATE_AVATAR_REQUEST";
export const USER_UPDATE_AVATAR_SUCCESS = "USER_UPDATE_AVATAR_SUCCESS";
export const USER_UPDATE_AVATAR_FAIL = "USER_UPDATE_AVATAR_FAIL";


export const USER_LIST_REQUEST = "USER_LIST_REQUEST";
export const USER_LIST_SUCCESS = "USER_LIST_SUCCESS";
export const USER_LIST_FAIL = "USER_LIST_FAIL";
