// src/components/UniqueComponent.js
import React, { useEffect } from 'react';
import 'aos/dist/aos.css';
import AOS from 'aos';

function UniqueComponent() {
    useEffect(() => {
        AOS.init({
            duration: 1000,
            once: false,
        });
    }, []);

    return (
        <div className="relative flex items-center justify-between h-screen bg-black text-white overflow-hidden">
            {/* Hintergrund mit dem Muster */}
            <div className="absolute inset-0 bg-[url('https://www.example.com/your-background-image.jpg')] bg-cover bg-center opacity-20"></div>

            {/* Linker Textbereich */}
            <div
                className="relative z-10 ml-16"
                data-aos="fade-right"
                data-aos-duration="1500"
            >
                <h1 className="text-6xl font-bold uppercase transition-transform transform hover:scale-110">
                    Alles <span className="text-yellow-500">außer</span> gewöhnlich
                </h1>
                <p className="text-lg mt-4 transition-transform transform hover:scale-105">
                    Vom Auftrag zum Unikat
                </p>
                <button className="mt-6 px-6 py-3 rounded-full bg-yellow-500 text-black font-semibold hover:bg-yellow-600 transition-transform transform hover:scale-110">
                    Hier klicken
                </button>
            </div>


            <div className="relative z-10 flex flex-col items-center justify-center space-y-4 mr-16">
                <div className="relative w-80 h-60 shadow-lg transition-transform transform hover:scale-105 hover:rotate-3 duration-300" data-aos="fade-up">
                    <img
                        src="https://www.example.com/your-background-image.jpg"
                        alt="Bild 1"
                        className="w-full h-full object-cover rounded-lg"
                    />
                </div>
                <div className="relative w-64 h-48 shadow-lg transition-transform transform hover:scale-105 hover:-rotate-3 duration-300" data-aos="fade-up" data-aos-delay="200">
                    <img
                        src="https://www.example.com/your-background-image.jpg"
                        alt="Bild 2"
                        className="w-full h-full object-cover rounded-lg"
                    />
                </div>
            </div>
        </div>
    );
}

export default UniqueComponent;
