import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FaEnvelope, FaPhone, FaMapMarkerAlt, FaCloudUploadAlt } from 'react-icons/fa';
import { getUserInfo, updateAvatar } from '../../actions/userActions';
import ProfilePictureModal from './ProfilePictureModal';

const UserInfo = ({ user, isOwnProfile }) => {
  const dispatch = useDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState(null);

  // Fetch user info only if `isOwnProfile` is true
  const userInfoState = useSelector((state) => state.userInfo);
  const { loading, userInfo, error } = userInfoState;

  // Fetch avatar update status only if `isOwnProfile` is true
  const updateAvatarState = useSelector((state) => state.updateAvatar);
  const { error: avatarUpdateError } = updateAvatarState;

  useEffect(() => {
    if (isOwnProfile) {
      dispatch(getUserInfo());
    }
  }, [dispatch, isOwnProfile]);

  const displayUser = isOwnProfile ? userInfo : user;

  const defaultAvatar = "https://via.placeholder.com/150?text=Avatar";
  const userAvatar = displayUser?.image
    ? (displayUser.image.startsWith("data:image") ? displayUser.image : `data:image/jpeg;base64,${displayUser.image}`)
    : defaultAvatar;

  const handleSave = (croppedImageFile) => {
    setPreviewImage(URL.createObjectURL(croppedImageFile));
    if (isOwnProfile) dispatch(updateAvatar(croppedImageFile));
    setIsModalOpen(false);
  };

  const handleRemove = () => {
    setPreviewImage(null);
    if (isOwnProfile) dispatch(updateAvatar(null));
  };

  return (
    <div className="p-6 bg-gradient-to-r from-blue-50 via-white to-blue-100 rounded-2xl shadow-lg">
      {loading ? (
        <p>Loading...</p>
      ) : error ? (
        <p>Error: {error}</p>
      ) : (
        <>
          <div className="flex items-center space-x-4 mb-6">
            <div
              className="relative w-24 h-24 rounded-full shadow-lg border-4 border-white overflow-hidden cursor-pointer group"
              onClick={() => isOwnProfile && setIsModalOpen(true)}
            >
              <img
                src={previewImage || userAvatar}
                alt="User Avatar"
                className="w-full h-full object-cover"
              />
              {isOwnProfile && (
                <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-0 group-hover:bg-opacity-60 transition-opacity duration-300">
                  <FaCloudUploadAlt className="text-white text-2xl opacity-0 group-hover:opacity-100 transition-opacity duration-300" />
                </div>
              )}
            </div>
            <div>
              <h2 className="text-3xl font-extrabold text-gray-900">{displayUser?.first_name} {displayUser?.last_name}</h2>
              <p className="text-lg font-medium text-gray-600">{displayUser?.position}</p>
            </div>
          </div>

          <div className="space-y-4 text-gray-800">
            <div className="flex items-center space-x-2">
              <FaEnvelope className="text-blue-500" />
              <span className="text-lg font-semibold">Email:</span>
              <span className="text-gray-700">{displayUser?.email}</span>
            </div>
            <div className="flex items-center space-x-2">
              <FaPhone className="text-green-500" />
              <span className="text-lg font-semibold">Telefon:</span>
              <span className="text-gray-700">{displayUser?.phone_number}</span>
            </div>
            <div className="flex items-center space-x-2">
              <FaMapMarkerAlt className="text-red-500" />
              <span className="text-lg font-semibold">Adresse:</span>
              <span className="text-gray-700">{displayUser?.address}</span>
            </div>
          </div>

          {isOwnProfile && (
            <>
              <ProfilePictureModal
                isOpen={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                onSave={handleSave}
                onRemove={handleRemove}
              />
              {avatarUpdateError && <p className="text-red-500 mt-2">{avatarUpdateError}</p>}
            </>
          )}
        </>
      )}
    </div>
  );
};

export default UserInfo;
