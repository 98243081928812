import React from 'react';

function ContactForm({ formData, handleChange, handleSubmit, loading }) {
  return (
    <div className="bg-gray-800 py-12 px-4 lg:px-32 relative">
      {/* Overlay während des Ladens */}
      {loading && (
        <div className="absolute inset-0 bg-black opacity-50 flex justify-center items-center z-10">
          {/* Spinner */}
          <div className="animate-spin border-4 border-t-4 border-yellow-500 rounded-full w-12 h-12"></div>
          <span className="text-white ml-4">Laden...</span>
        </div>
      )}

      {/* Formular */}
      <form className="space-y-6" onSubmit={handleSubmit}>
        {/* Vorname und Nachname */}
        <div className="flex flex-col lg:flex-row lg:space-x-8 space-y-4 lg:space-y-0">
          <div className="flex-1">
            <label className="block text-sm font-medium text-gray-300">Vorname*</label>
            <input
              type="text"
              name="firstName"
              value={formData.firstName}
              onChange={handleChange}
              required
              className="w-full mt-1 px-4 py-2 bg-gray-900 text-white border border-gray-600 rounded-lg focus:outline-none focus:ring-2 focus:ring-yellow-500"
            />
          </div>
          <div className="flex-1">
            <label className="block text-sm font-medium text-gray-300">Nachname*</label>
            <input
              type="text"
              name="lastName"
              value={formData.lastName}
              onChange={handleChange}
              required
              className="w-full mt-1 px-4 py-2 bg-gray-900 text-white border border-gray-600 rounded-lg focus:outline-none focus:ring-2 focus:ring-yellow-500"
            />
          </div>
        </div>

        {/* Betreff */}
        <div>
          <label className="block text-sm font-medium text-gray-300">Betreff*</label>
          <input
            type="text"
            name="subject"
            value={formData.subject || ""}
            onChange={handleChange}
            required
            className="w-full mt-1 px-4 py-2 bg-gray-900 text-white border border-gray-600 rounded-lg focus:outline-none focus:ring-2 focus:ring-yellow-500"
          />
        </div>

        {/* E-Mail und Telefonnummer */}
        <div className="flex flex-col lg:flex-row lg:space-x-8 space-y-4 lg:space-y-0">
          <div className="flex-1">
            <label className="block text-sm font-medium text-gray-300">E-Mail Adresse*</label>
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
              className="w-full mt-1 px-4 py-2 bg-gray-900 text-white border border-gray-600 rounded-lg focus:outline-none focus:ring-2 focus:ring-yellow-500"
            />
          </div>
          <div className="flex-1">
            <label className="block text-sm font-medium text-gray-300">Telefonnummer</label>
            <input
              type="tel"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              className="w-full mt-1 px-4 py-2 bg-gray-900 text-white border border-gray-600 rounded-lg focus:outline-none focus:ring-2 focus:ring-yellow-500"
            />
          </div>
        </div>

        {/* Nachricht */}
        <div>
          <label className="block text-sm font-medium text-gray-300">Ihre Nachricht*</label>
          <textarea
            name="message"
            value={formData.message}
            onChange={handleChange}
            required
            className="w-full mt-1 px-4 py-2 bg-gray-900 text-white border border-gray-600 rounded-lg focus:outline-none focus:ring-2 focus:ring-yellow-500 h-32 resize-none"
          ></textarea>
        </div>

        {/* Absenden-Button */}
        <button
          type="submit"
          className="w-full bg-yellow-500 text-gray-900 font-semibold py-3 rounded-lg hover:bg-yellow-600 transition duration-300"
        >
          Nachricht senden
        </button>
      </form>
    </div>
  );
}

export default ContactForm;
