// src/TestimonialComponent.js
import React from 'react';

function TestimonialComponent() {
    return (
        <section className="py-16 bg-gray-50 text-center">
            <h2 className="text-3xl font-bold mb-8" data-aos="fade-up">Was unsere Kunden sagen</h2>
            <div className="flex justify-center space-x-8">
                <div className="bg-white p-6 shadow-lg rounded-lg max-w-xs" data-aos="fade-up" data-aos-delay="200">
                    <p className="mb-4">„Das Team von XYZ Reinigungsservice war unglaublich! Sie haben meine Wohnung blitzsauber hinterlassen.“</p>
                    <p className="font-semibold">Anna Müller</p>
                </div>
                <div className="bg-white p-6 shadow-lg rounded-lg max-w-xs" data-aos="fade-up" data-aos-delay="400">
                    <p className="mb-4">„Schneller Service und gründliche Reinigung. Sehr zu empfehlen!“</p>
                    <p className="font-semibold">Maximilian Schmidt</p>
                </div>
            </div>
        </section>
    );
}

export default TestimonialComponent;
