// src/NavComponent.js
import React, { useState } from 'react';
import '../style/logoStyle.css';
import {Link} from "react-router-dom";

function NavComponent() {
    // State für das Mobile-Menü
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    // Funktion zum Umschalten des Menüs
    const toggleMenu = () => setIsMenuOpen(!isMenuOpen);

    return (
        <header className="bg-gray-900 text-white p-4 md:p-6 shadow-md">
            <div className="container mx-auto flex flex-wrap items-center justify-between">

                {/* Logo */}
                <div className="logo-text">
                    <Link to="/" className="">
                    <span className="primary-text ">
                        <span className="highlight-letter ">B</span>remer
                        <span className="highlight-letter">M</span>ulti
                        <span className="highlight-letter">S</span>ervice
                    </span>
                    <div className="parallelogram">
                        <span className="secondary-text">DIENSTLEISTUNG GmbH</span>
                    </div>
                    </Link>
                </div>

                {/* Navigation - Desktop-Ansicht */}
                <nav className="hidden md:flex space-x-6 lg:space-x-8 ">
                    <Link to="/" className="hover:text-gray-300 text-yellow-500 ">Home</Link>
                    <Link to="#" className="hover:text-gray-300 text-yellow-500">About</Link>
                    <Link to="#" className="hover:text-gray-300 text-yellow-500">Services</Link>
                    <Link to="/contact" className="hover:text-gray-300 text-yellow-500">Contact</Link>
                </nav>

                {/* Call to Action Button - Desktop-Ansicht */}
                <button
                    className="hidden md:inline-block bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded-md">
                    Get in Touch
                </button>

                {/* Mobile Menu Icon */}
                <div className="md:hidden">
                    <button onClick={toggleMenu} className="text-white">
                        {/* Mobile Menü-Symbol */}
                        <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24"
                             xmlns="http://www.w3.org/2000/svg">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                  d="M4 6h16M4 12h16M4 18h16"></path>
                        </svg>
                    </button>
                </div>
            </div>

            {/* Mobile Menu - Anzeigen, wenn isMenuOpen true ist */}
            {isMenuOpen && (
                <div className="md:hidden bg-gray-800 p-4 space-y-4">
                    <Link to="/" className="block hover:text-gray-300">Home</Link>
                    <Link to="#" className="block hover:text-gray-300">About</Link>
                    <Link to="#" className="block hover:text-gray-300">Services</Link>
                    <Link to="/contact" className="block hover:text-gray-300">Contact</Link>
                    <button className="w-full bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 rounded-md">
                        Get in Touch
                    </button>
                </div>
            )}
        </header>
    );
}

export default NavComponent;
