import React, { useState, useRef } from 'react';
import Modal from 'react-modal';
import { useDropzone } from 'react-dropzone';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';

Modal.setAppElement('#root');

const modalStyles = {
  content: {
    width: '350px',
    height: '450px',
    margin: 'auto',
    borderRadius: '10px',
  },
};

const dropzoneStyle = {
  border: '2px dashed #ccc',
  borderRadius: '10px',
  padding: '20px',
  textAlign: 'center',
  transition: 'border 0.3s ease',
};

const ProfilePictureModal = ({ isOpen, onClose, onSave, onRemove }) => {
  const [selectedImage, setSelectedImage] = useState(null);
  const cropperRef = useRef(null);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: 'image/*',
    maxSize: 5 * 1024 * 1024, // 5MB limit
    onDrop: (acceptedFiles) => {
      const file = acceptedFiles[0];
      if (file) {
        setSelectedImage(URL.createObjectURL(file));
      }
    },
    onDropRejected: () => {
      alert('Please select an image file smaller than 5MB.');
    },
  });

  const handleSave = () => {
    if (cropperRef.current) {
      const croppedImageBase64 = cropperRef.current.cropper.getCroppedCanvas().toDataURL();
      const croppedImageFile = base64ToFile(croppedImageBase64, 'avatar.jpg');
      onSave(croppedImageFile);
      onClose();
    }
  };

  const handleRemove = () => {
    setSelectedImage(null);
    onRemove();
    onClose();
  };

  const base64ToFile = (base64, filename = 'avatar.jpg') => {
  const arr = base64.split(',');
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
};


  return (
    <Modal isOpen={isOpen} onRequestClose={onClose} style={modalStyles}>
      <h2 className="text-center font-bold text-lg mb-4">Profilbild ändern</h2>
      {!selectedImage ? (
        <div
          {...getRootProps()}
          style={isDragActive ? { ...dropzoneStyle, borderColor: '#007bff' } : dropzoneStyle}
          className="border-dashed rounded-lg p-6 text-center"
        >
          <input {...getInputProps()} />
          <p className="text-gray-500">Ihre Bilder hierher ziehen und ablegen.</p>
          <p>oder</p>
          <button aria-label="Upload photo" className="bg-gray-100 px-4 py-2 rounded mt-2">Foto hochladen</button>
        </div>
      ) : (
        <div className="relative">
          <Cropper
            src={selectedImage}
            style={{ height: 250, width: '100%' }}
            initialAspectRatio={1}
            aspectRatio={1}
            guides={false}
            ref={cropperRef}
            viewMode={1}
            zoomable
            scalable
          />
          <div className="flex justify-between items-center mt-4">
            <button onClick={handleRemove} className="bg-red-500 text-white px-4 py-2 rounded">Bild entfernen</button>
            <button onClick={handleSave} className="bg-blue-500 text-white px-4 py-2 rounded">Hochladen</button>
          </div>
        </div>
      )}
      <button onClick={onClose} className="mt-4 bg-gray-200 px-4 py-2 rounded w-full">Abbrechen</button>
    </Modal>
  );
};

export default ProfilePictureModal;