// src/PricingComponent.js
import React from 'react';

function PricingComponent() {
    return (
        <section className="py-16 bg-gray-200 text-center">
            <h2 className="text-3xl font-bold mb-8">Unsere Preise</h2>
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
                <div className="bg-white p-6 shadow-lg rounded-lg hover:shadow-xl transition-all">
                    <h3 className="text-2xl font-semibold mb-4">Standard Reinigung</h3>
                    <p className="mb-4">Für kleine Wohnungen und Einzelräume.</p>
                    <p className="text-xl font-bold">€50</p>
                    <button className="bg-blue-500 hover:bg-blue-600 text-white py-2 px-6 rounded-md mt-4">
                        Jetzt buchen
                    </button>
                </div>
                <div className="bg-white p-6 shadow-lg rounded-lg hover:shadow-xl transition-all">
                    <h3 className="text-2xl font-semibold mb-4">Büroreinigung</h3>
                    <p className="mb-4">Für kleine bis mittelgroße Büros.</p>
                    <p className="text-xl font-bold">€120</p>
                    <button className="bg-blue-500 hover:bg-blue-600 text-white py-2 px-6 rounded-md mt-4">
                        Jetzt buchen
                    </button>
                </div>
                <div className="bg-white p-6 shadow-lg rounded-lg hover:shadow-xl transition-all">
                    <h3 className="text-2xl font-semibold mb-4">Fensterreinigung</h3>
                    <p className="mb-4">Fensterreinigung für alle Arten von Fenstern.</p>
                    <p className="text-xl font-bold">€30</p>
                    <button className="bg-blue-500 hover:bg-blue-600 text-white py-2 px-6 rounded-md mt-4">
                        Jetzt buchen
                    </button>
                </div>
            </div>
        </section>
    );
}

export default PricingComponent;
