// src/ParallaxSection.js
import React from 'react';

function ParallaxSection({ backgroundImage, backgroundColor, children }) {
    const backgroundStyle = backgroundImage
        ? `url(${backgroundImage})`
        : backgroundColor || 'transparent';

    return (
        <div
            className="parallax"
            style={{
                backgroundImage: backgroundStyle,
                backgroundAttachment: 'fixed',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                height: '500px',
            }}
        >
            <div className="content">{children}</div>
        </div>
    );
}

export default ParallaxSection;
