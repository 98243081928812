import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { login } from '../actions/userActions';
import ToastContainer from '../components/ToastContainer';
import { USER_LOGIN_RESET } from "../constants/userConstants";
import { useNavigate } from "react-router-dom";

function LoginScreen() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [toasts, setToasts] = useState([]); // Zustand für Toasts
  const [toastPosition, setToastPosition] = useState('top-right'); // Toast-Position

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const userLogin = useSelector((state) => state.userLogin);
  const { loading, error, userInfo } = userLogin;

  // Funktion zum Anzeigen von Toast-Nachrichten
  const showToast = (message, type, position) => {
    const newToast = { message, type, position, id: new Date().getTime() };
    setToasts((prevToasts) => [...prevToasts, newToast]);
    setTimeout(() => {
      setToasts((prevToasts) => prevToasts.filter((toast) => toast.id !== newToast.id));
    }, 3000);
  };

  // Formular-Submit-Handler
  const handleSubmit = (e) => {
    e.preventDefault();

    if (username === '' || password === '') {
      showToast('Bitte alle Felder ausfüllen.', 'error', toastPosition);
      return;
    }

    const normalizedUsername = username.includes("@") ? username.toLowerCase() : username;
    dispatch(login(normalizedUsername, password));
  };

  // useEffect für das Verwalten von Ladezustand, Fehlern und erfolgreichem Login
  useEffect(() => {
    if (loading) {
      showToast('Logging in...', 'loading', toastPosition);
      dispatch({ type: USER_LOGIN_RESET });
    }
    if (error) {
      showToast(error, 'error', toastPosition);
      dispatch({ type: USER_LOGIN_RESET });
    }
    if (userInfo) {
      navigate("/admin");
    }
  }, [loading, error, userInfo, toastPosition, dispatch, navigate]);

  return (
    <div className="min-h-screen flex items-center justify-center bg-gradient-to-r from-blue-500 to-indigo-600 animate-fadeIn">
      <div className="bg-white p-8 rounded-lg shadow-lg max-w-sm w-full animate-slideUp">
        {/* Logo und Text */}
        <div className="logo-text mb-6 animate-fadeIn relative">
          <span className="primary-text-login text-1xl font-extrabold text-blue-600">
            <span className="highlight-letter-login text-2xl animate-outerB">B</span>
            remer
            <span className="highlight-letter-login text-2xl animate-outerM">M</span>
            ulti
            <span className="highlight-letter-login text-2xl animate-outerS">S</span>
            ervice
          </span>
          <div className="parallelogram mt-2 text-lg italic text-blue-800">
            <span className="secondary-text">DIENSTLEISTUNG GmbH</span>
          </div>
        </div>

        {/* Login Titel */}
        <h2 className="text-2xl font-semibold text-gray-800 text-center mb-6 animate-fadeIn">
          Login
        </h2>

        {/* Toast Container */}
        <ToastContainer toasts={toasts} />

        {/* Login-Formular */}
        <form onSubmit={handleSubmit} className="space-y-4">
          <input
            type="text"
            placeholder="Username"
            className="w-full px-4 py-2 rounded-md border border-gray-300 focus:ring-2 focus:ring-blue-500 focus:outline-none transition-transform transform hover:scale-105"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
          <input
            type="password"
            placeholder="Password"
            className="w-full px-4 py-2 rounded-md border border-gray-300 focus:ring-2 focus:ring-blue-500 focus:outline-none transition-transform transform hover:scale-105"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <button
            type="submit"
            className="w-full py-2 bg-blue-600 text-white font-semibold rounded-md hover:bg-blue-700 transition duration-300 transform hover:scale-105"
          >
            Login
          </button>
        </form>
      </div>
    </div>
  );
}

export default LoginScreen;
