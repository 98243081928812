// SocialMediaCard.js
import React from 'react';
import { FaFacebookF, FaTiktok, FaLinkedinIn } from 'react-icons/fa';

const SocialMediaCard = ({ platform, stats }) => {
  const platformStyles = {
    Facebook: 'from-blue-600 to-blue-400',
    TikTok: 'from-black to-gray-800',
    LinkedIn: 'from-blue-700 to-blue-500',
  };

  const getIcon = () => {
    switch (platform) {
      case 'Facebook': return <FaFacebookF className="text-3xl" />;
      case 'TikTok': return <FaTiktok className="text-3xl" />;
      case 'LinkedIn': return <FaLinkedinIn className="text-3xl" />;
      default: return null;
    }
  };

  return (
    <div className={`p-6 rounded-2xl shadow-lg bg-gradient-to-r ${platformStyles[platform]} text-white transform transition hover:scale-105 duration-200`}>
      <div className="flex justify-between items-center">
        {getIcon()}
        <div className="text-right">
          <p className="text-2xl font-bold">{stats.followers || stats.friends}</p>
          <p className="text-sm">{platform === 'TikTok' ? 'Followers' : 'Friends'}</p>
          <p className="text-xs mt-1">{stats.videos || stats.feeds} {platform === 'TikTok' ? 'Videos' : 'Feeds'}</p>
        </div>
      </div>
      <h3 className="text-lg font-semibold mt-4">{platform}</h3>
    </div>
  );
};

export default SocialMediaCard;
