// src/ServiceListComponent.js
import React from 'react';

function ServiceListComponent() {
    return (
        <section className="py-16 px-4 bg-gray-100 text-center">
            <h2 className="text-3xl font-bold mb-8" data-aos="fade-up">Unsere Dienstleistungen</h2>
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
                <div className="bg-white p-6 shadow-lg rounded-lg hover:shadow-xl transition-all" data-aos="fade-up" data-aos-delay="200">
                    <h3 className="text-2xl font-semibold mb-4">Wohnungsreinigung</h3>
                    <p className="mb-4">Gründliche Reinigung für Ihre Wohnung, damit Sie sich entspannen können.</p>
                    <button className="bg-blue-500 hover:bg-blue-600 text-white py-2 px-6 rounded-md">
                        Jetzt buchen
                    </button>
                </div>
                <div className="bg-white p-6 shadow-lg rounded-lg hover:shadow-xl transition-all" data-aos="fade-up" data-aos-delay="400">
                    <h3 className="text-2xl font-semibold mb-4">Büroreinigung</h3>
                    <p className="mb-4">Für ein sauberes und produktives Arbeitsumfeld.</p>
                    <button className="bg-blue-500 hover:bg-blue-600 text-white py-2 px-6 rounded-md">
                        Jetzt buchen
                    </button>
                </div>
                <div className="bg-white p-6 shadow-lg rounded-lg hover:shadow-xl transition-all" data-aos="fade-up" data-aos-delay="600">
                    <h3 className="text-2xl font-semibold mb-4">Fensterreinigung</h3>
                    <p className="mb-4">Kristallklare Fenster, die den Unterschied machen.</p>
                    <button className="bg-blue-500 hover:bg-blue-600 text-white py-2 px-6 rounded-md">
                        Jetzt buchen
                    </button>
                </div>
            </div>
        </section>
    );
}

export default ServiceListComponent;
