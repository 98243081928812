import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import UserInfo from './UserInfo';
import { getUserList } from '../../actions/userActions';

const UserList = () => {
  const dispatch = useDispatch();
  const [selectedUser, setSelectedUser] = useState(null); // State to hold the selected user

  // Access the user list from Redux state
  const userListState = useSelector((state) => state.userList);
  const { users, loading, error } = userListState;

  // Fetch user list on component mount
  useEffect(() => {
    dispatch(getUserList());
  }, [dispatch]);

  // Handler to select a user and display their full information
  const handleUserClick = (user) => {
    setSelectedUser(user); // Set the selected user to show full information
  };

  return (
    <div className="p-8 bg-white rounded-4xl shadow-lg hover:shadow-2xl transition duration-300">

      {loading ? (
        <p>Loading...</p>
      ) : error ? (
        <p className="text-red-500">Error: {error}</p>
      ) : (
        <div className="space-y-6">
          {/* If a user is selected, display their full info; otherwise, show the user list */}
          {selectedUser ? (
            <div className="p-4 border rounded-lg shadow-md bg-blue-50">
              <button
                onClick={() => setSelectedUser(null)}
                className="text-blue-500 underline mb-4"
              >
                Zurück zur Liste
              </button>
              <UserInfo user={selectedUser} isOwnProfile={false} />
            </div>
          ) : (
            users.map((user) => (
              <div
                key={user.username}
                className="flex items-center space-x-4 p-4 border-b cursor-pointer hover:bg-gray-50"
                onClick={() => handleUserClick(user)}
              >
                <img
                  src={
                    user.image
                      ? `data:image/jpeg;base64,${user.image}`
                      : "https://via.placeholder.com/50?text=Avatar"
                  }
                  alt="User Avatar"
                  className="w-12 h-12 rounded-full object-cover"
                />
                <span className="text-lg font-semibold text-gray-800">
                  {user.first_name} {user.last_name}
                </span>
              </div>
            ))
          )}
        </div>
      )}
    </div>
  );
};

export default UserList;
