import axios from "axios";
import {
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGIN_FAIL,
  USER_LOGOUT_REQUEST,
  USER_LOGOUT_SUCCESS,
  USER_INFO_SUCCESS,
  USER_INFO_FAIL,
  USER_INFO_REQUEST,
  USER_UPDATE_AVATAR_FAIL,
  USER_UPDATE_AVATAR_SUCCESS,
  USER_UPDATE_AVATAR_REQUEST,
  USER_LIST_RESET,
  USER_LIST_FAIL,
  USER_LIST_SUCCESS,
  USER_LIST_REQUEST,

} from "../constants/userConstants";
import config from "../config";
import { handleError } from "../utils/errorMessageUtils";



const apiBaseUrl = `${config.PROTOCOL}://${config.HOST}${config.PORT ? `:${config.PORT}` : ""}`;

// API Endpoints
export const loginUrl = `${apiBaseUrl}/accounts/login/`;
export const logoutUrl = `${apiBaseUrl}/api/users/logout`;
export const userInfoUrl = `${apiBaseUrl}/accounts/user-info/`; // New endpoint for user info
export const updateAvatarUrl = `${apiBaseUrl}/accounts/update-avatar/`;
export const userListUrl = `${apiBaseUrl}/accounts/users/`;

// Actions
export const login = (username, password) => async (dispatch) => {
  try {
    dispatch({ type: USER_LOGIN_REQUEST });

    const { data } = await axios.post(loginUrl, { username, password });

    if (!data || !data.access) {
      throw new Error("Access token not received from server");
    }

    dispatch({ type: USER_LOGIN_SUCCESS, payload: data });
    localStorage.setItem("userInfo", JSON.stringify(data));
  } catch (error) {
    const errorMessage = handleError(error, dispatch);
    dispatch({ type: USER_LOGIN_FAIL, payload: errorMessage });
  }
};

export const logout = () => async (dispatch, getState) => {
  try {
    dispatch({ type: USER_LOGOUT_REQUEST });
    dispatch({ type: USER_LIST_RESET });
    const {
      userLogin: { userInfo },
    } = getState();

    if (!userInfo || !userInfo.access) {
      dispatch({ type: USER_LOGOUT_SUCCESS });
      dispatch({ type: USER_LIST_RESET });
      localStorage.removeItem("userInfo");
      window.location.href = "/login";
      return;
    }

    const axiosConfig = {
      headers: { Authorization: `Bearer ${userInfo.access}` },
    };

    await axios.post(logoutUrl, {}, axiosConfig);

    dispatch({ type: USER_LOGOUT_SUCCESS });
    dispatch({ type: USER_LIST_RESET });
    localStorage.removeItem("userInfo");
    window.location.href = "/login";
  } catch (error) {
    dispatch({ type: USER_LOGOUT_SUCCESS });
    dispatch({ type: USER_LIST_RESET });
    localStorage.removeItem("userInfo");
    window.location.href = "/login";
  }
};

export const getUserInfo = () => async (dispatch, getState) => {
  try {
    dispatch({ type: USER_INFO_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const axiosConfig = {
      headers: { Authorization: `Bearer ${userInfo.access}` },
    };

    const { data } = await axios.get(userInfoUrl, axiosConfig);

    dispatch({ type: USER_INFO_SUCCESS, payload: data });
  } catch (error) {
    const errorMessage = handleError(error, dispatch);
    dispatch({ type: USER_INFO_FAIL, payload: errorMessage });
  }
};

export const updateAvatar = (avatarFile) => async (dispatch, getState) => {
  try {
    dispatch({ type: USER_UPDATE_AVATAR_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const formData = new FormData();
    formData.append("avatar", avatarFile);

    const axiosConfig = {
      headers: {
        Authorization: `Bearer ${userInfo.access}`,
        "Content-Type": "multipart/form-data"
      },
    };

    const { data } = await axios.put(updateAvatarUrl, formData, axiosConfig);

    dispatch({ type: USER_UPDATE_AVATAR_SUCCESS, payload: data });
    dispatch({ type: USER_INFO_SUCCESS, payload: data });  // Update user info with the new avatar
  } catch (error) {
    const errorMessage = handleError(error, dispatch);
    dispatch({ type: USER_UPDATE_AVATAR_FAIL, payload: errorMessage });
  }
};


export const getUserList = () => async (dispatch, getState) => {
  try {
    dispatch({ type: USER_LIST_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const axiosConfig = {
      headers: { Authorization: `Bearer ${userInfo.access}` },
    };

    const { data } = await axios.get(userListUrl, axiosConfig);

    dispatch({ type: USER_LIST_SUCCESS, payload: data.results }); // Use data.results here
  } catch (error) {
    const errorMessage = handleError(error, dispatch);
    dispatch({ type: USER_LIST_FAIL, payload: errorMessage });
  }
};
