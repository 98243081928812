import React, { useEffect } from 'react';

function Toast({ message, type, position, onClose }) {
  useEffect(() => {
    const timer = setTimeout(() => {
      onClose();
    }, 3000);
    return () => clearTimeout(timer);
  }, [onClose]);

  const getToastStyle = () => {
    switch (type) {
      case 'success':
        return 'bg-green-500 text-white';
      case 'error':
        return 'bg-red-500 text-white';
      case 'info':
        return 'bg-blue-500 text-white';
      case 'warn':
        return 'bg-yellow-500 text-black';
      case 'loading':
        return 'bg-gray-500 text-white';
      default:
        return 'bg-gray-500 text-white';
    }
  };

  const getPositionStyle = () => {
    switch (position) {
      case 'top-left':
        return 'top-5 left-5';
      case 'top-right':
        return 'top-5 right-5';
      case 'bottom-left':
        return 'bottom-5 left-5';
      case 'bottom-right':
        return 'bottom-5 right-5';
      case 'center':
        return 'top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2';
      default:
        return 'top-5 right-5';
    }
  };
  const renderLoading = () => {
    return (
      <div className="flex justify-center items-center">
        <div className="animate-spin border-t-4 border-b-4 border-white w-6 h-6 rounded-full"></div>
        <span className="ml-2">Loading...</span>
      </div>
    );
  };

  return (
    <div
      className={`fixed p-4 mb-4 rounded-lg shadow-lg w-72 transition-all transform opacity-100 z-50 ${getToastStyle()} ${getPositionStyle()}`}
    >
      <div className="flex justify-between items-center">
        {type === 'loading' ? (
          renderLoading()
        ) : (
          <>
            <span>{message}</span>
            <button onClick={onClose} className="text-white ml-2">
              &times;
            </button>
          </>
        )}
      </div>
    </div>
  );
}

export default Toast;
