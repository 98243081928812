// CustomerRequests.js
import React, { useState, useEffect } from 'react';

const CustomerRequests = () => {
  const [requests, setRequests] = useState([]);

  useEffect(() => {
    fetch('/api/customer-requests')
      .then(response => response.json())
      .then(data => setRequests(data));
  }, []);

  return (
    <div className="p-8 bg-white rounded-2xl shadow-md transition hover:shadow-lg duration-300">
      <h2 className="text-2xl font-semibold text-gray-800 mb-4">Kundenanfragen</h2>
      <table className="w-full text-left border-separate border-spacing-2">
        <thead>
          <tr className="text-gray-500">
            <th>ID</th>
            <th>Name</th>
            <th>Anfrage</th>
            <th>Status</th>
            <th>Aktionen</th>
          </tr>
        </thead>
        <tbody>
          {requests.map((request) => (
            <tr key={request.id} className="bg-gray-50 hover:bg-gray-100">
              <td>{request.id}</td>
              <td>{request.name}</td>
              <td>{request.message}</td>
              <td>{request.status}</td>
              <td>
                <button className="bg-blue-600 text-white px-4 py-1 rounded-full hover:bg-blue-700">
                  Antwort
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default CustomerRequests;
