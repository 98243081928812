// src/components/dashboard/StatisticsCard.js
import React from 'react';

const StatisticsCard = ({ title, value, description, icon }) => {
  return (
    <div className="bg-gradient-to-br from-pink-100 via-purple-100 to-blue-100 rounded-lg shadow-md p-4 flex flex-col items-center text-center space-y-2">
      <div className="text-3xl text-purple-600 mb-1">{icon}</div> {/* Icon */}
      <p className="text-2xl font-bold text-gray-900">{value}</p> {/* Value */}
      <h3 className="text-lg font-semibold text-gray-800">{title}</h3> {/* Title */}
      <p className="text-xs text-gray-500">{description}</p> {/* Description */}
    </div>
  );
};

export default StatisticsCard;
