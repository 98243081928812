import React, { useState, useEffect } from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import { useDispatch, useSelector } from "react-redux";
import { createMessage } from "../actions/contactAction";
import { SEND_MESSAGE_RESET } from "../constants/contactConstants";
import ToastContainer from '../components/ToastContainer';
import NavComponent from "../components/NavComponent";
import ContactForm from "../components/ContactForm";
import { motion } from "framer-motion";

// Nicht vergessen: Importiere die Leaflet-CSS-Datei, damit die Karten korrekt dargestellt werden
import 'leaflet/dist/leaflet.css';

function ContactScreen() {
  const sendMessage = useSelector((state) => state.sendMessage);
  const { loading, error, success } = sendMessage;
  const [toasts, setToasts] = useState([]);
  const [toastPosition, setToastPosition] = useState('top-right');
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    subject: '',
    email: '',
    phone: '',
    message: ''
  });

  const showToast = (message, type) => {
    const newToast = { message, type, id: Date.now() };
    setToasts((prevToasts) => [...prevToasts, newToast]);
    setTimeout(() => {
      setToasts((prevToasts) => prevToasts.filter((toast) => toast.id !== newToast.id));
      dispatch({ type: SEND_MESSAGE_RESET });
    }, 3000);
  };

  useEffect(() => {
    if (success) {
      showToast('Kontakt erfolgreich erstellt!', 'success');
      setFormData({ firstName: '', lastName: '', email: '', phone: '', message: '' });
    }
    if (error) {
      showToast(error, 'error');
    }
  }, [success, error, dispatch]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (formData.firstName && formData.lastName && formData.email && formData.message) {
      dispatch(createMessage(formData.firstName, formData.email, formData.subject, formData.message));
    } else {
      showToast('Bitte alle erforderlichen Felder ausfüllen.', 'error');
    }
  };

  return (
    <div className="bg-gray-800 text-white">
      <NavComponent />
      <div className="container mx-auto shadow-lg rounded-lg">
        {/* Header-Abschnitt mit Animation */}
        <div
          className="relative bg-cover bg-center h-80 flex items-center justify-center text-center mt-8 shadow-lg rounded-lg"
          style={{ backgroundImage: "url('path/to/your/image.jpg')" }}
        >
          <div className="absolute inset-0 bg-black opacity-50 shadow-md rounded-lg"></div>
          <motion.div
            className="relative z-10 px-4 shadow-md rounded-lg"
            initial={{ opacity: 0, x: -100 }}  // Start außerhalb des Bildschirms
            animate={{ opacity: 1, x: 0 }}    // Endet in der normalen Position
            transition={{ duration: 1.5 }}     // Dauer der Animation
          >
            <h1 className="text-4xl font-bold text-yellow-500">Wir sind bereit, Ihre Fragen zu beantworten!</h1>
            <p className="text-lg mt-2">Kontaktieren Sie uns!</p>
          </motion.div>
        </div>

        {/* Karte mit Animation */}
        <motion.div
          className="mt-8"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1 }}
        >
          <h2 className="text-2xl font-bold text-yellow-500">Finden Sie uns hier:</h2>
          <MapContainer
            center={[48.8566, 2.3522]} // Paris Koordinaten
            zoom={12}
            style={{ width: '100%', height: '400px', borderRadius: '8px' }}
          >
            <TileLayer
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            />
            <Marker position={[48.8566, 2.3522]}>
              <Popup>Unser Standort in Paris!</Popup>
            </Marker>
          </MapContainer>
        </motion.div>

        {/* Kontaktformular mit Fade-In und Bounce */}
        <motion.div
          className="shadow-lg rounded-lg mt-8"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1 }}
        >
          <motion.div
            initial={{ y: -100 }}
            animate={{ y: 0 }}
            transition={{
              type: "spring",
              stiffness: 100,
              damping: 20,
            }}
          >
            <ContactForm
              formData={formData}
              handleChange={handleChange}
              handleSubmit={handleSubmit}
              loading={loading}
            />
          </motion.div>
        </motion.div>

        {/* Toast-Benachrichtigungen */}
        <div className="shadow-md rounded-lg">
          <ToastContainer toasts={toasts} />
        </div>
      </div>
    </div>
  );
}

export default ContactScreen;