import {
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGIN_FAIL,
  USER_LOGOUT,
  USER_LOGOUT_REQUEST,
  USER_LOGOUT_SUCCESS,
  USER_LOGIN_RESET,
  USER_INFO_REQUEST,
  USER_INFO_SUCCESS,
  USER_INFO_FAIL,
  USER_UPDATE_AVATAR_REQUEST,
  USER_UPDATE_AVATAR_SUCCESS,
  USER_UPDATE_AVATAR_FAIL,
  USER_LIST_REQUEST,
  USER_LIST_SUCCESS,
  USER_LIST_FAIL,

} from "../constants/userConstants";



export const userLoginReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_LOGIN_REQUEST:
      return { loading: true };
    case USER_LOGIN_SUCCESS:
      return { loading: false, userInfo: action.payload };
    case USER_LOGIN_FAIL:
      return { loading: false, error: action.payload };
    case USER_LOGIN_RESET:
      return { };
    case USER_LOGOUT_REQUEST:
      return { ...state, loadingLogout: true };
    case USER_LOGOUT_SUCCESS:
    case USER_LOGOUT:
      return {};
    default:
      return state;
  }
};

export const userInfoReducer = (state = { userInfo: null }, action) => {
  switch (action.type) {
    case USER_INFO_REQUEST:
      return { loading: true, userInfo: null };
    case USER_INFO_SUCCESS:
      return { loading: false, userInfo: action.payload };
    case USER_INFO_FAIL:
      return { loading: false, error: action.payload, userInfo: null };
    default:
      return state;
  }
};

export const updateAvatarReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_UPDATE_AVATAR_REQUEST:
      return { loading: true };
    case USER_UPDATE_AVATAR_SUCCESS:
      return { loading: false, success: true, userInfo: action.payload };
    case USER_UPDATE_AVATAR_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const userListReducer = (state = { users: [] }, action) => {
  switch (action.type) {
    case USER_LIST_REQUEST:
      return { loading: true, users: [] };
    case USER_LIST_SUCCESS:
      return { loading: false, users: action.payload };
    case USER_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};